@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent: map.get($color-config, "accent");
  $foreground: map.get($color-config, "foreground");

  .small-value {
    color: mat.get-color-from-palette($foreground, "quantis-grey-2");
  }
}
