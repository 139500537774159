@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent: map.get($color-config, "accent");
  $foreground: map.get($color-config, "foreground");

  .progress-bar {
    background-color: mat.get-color-from-palette($accent, 100);
  }

  .improvement {
    .progress-bar {
      background-color: mat.get-color-from-palette($foreground, "improvement");
    }
  }

  .regression {
    .progress-bar {
      background-color: mat.get-color-from-palette($foreground, "regression");
    }
  }

  .small-value {
    color: mat.get-color-from-palette($foreground, "quantis-grey-2");

    .progress-bar {
      background-color: mat.get-color-from-palette($foreground, "logo-grey");
    }

  }
}
